import { openDB } from 'idb';
const fileStoreName = 'files';
let database;
// Opens new DB instance or returns existing one
async function getDb() {
    if (database) {
        return database;
    }
    database = await openDB('db', 1, {
        // Upgrade is only called the first time this version of the database is opened.
        upgrade(db) {
            db.createObjectStore(fileStoreName);
        }
    });
    return database;
}
export default {
    async get(key) {
        const db = await getDb();
        return await db.get(fileStoreName, key);
    },
    async set(key, val) {
        const db = await getDb();
        await db.put(fileStoreName, val, key);
    },
    async delete(key) {
        const db = await getDb();
        await db.delete(fileStoreName, key);
    }
};
