<template>
  <div class="container">
    <vue3-progress />
    <div class="row mx-2">
      <router-view />
      <div>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FeedbackEngine',
  inject: ['Api']
})
</script>
