<!-- Component for all one-line inputs -->
<template>
  <div>
    <!-- File inputs -->
    <div v-if="element.subType === 'file'">
      <div class="input-group mb-2 shadow">
        <input
          v-if="!answers[element.nodeName].value || (file && !usingCachedFile)"
          :type="element.subType"
          @change="uploadFile($event)"
          :accept="this.Config.file.allowedFileExtensions.map(ext => '.' + ext).join(', ')"
          :placeholder="translations['survey-choose-file']"
          :id="element.nodeName"
          :name="element.nodeName"
          ref="fileInput"
          class="form-control py-2"
          aria-label="Choose file to upload"
          :aria-describedby="`${element.type}-${element.subType}-${currentPageNumber}-${elementIndex}`"
        />
        <input
          v-else
          :value="answers[element.nodeName].value"
          type="text"
          :name="element.nodeName"
          class="form-control py-2"
          :aria-describedby="`${element.type}-${element.subType}-${currentPageNumber}-${elementIndex}`"
          readonly
        />
        <span
          :id="`${element.type}-${element.subType}-${currentPageNumber}-${elementIndex}`"
          class="input-group-text"
        >
          <button
            @click="clearFile()"
            class="btn btn-sm bg-gray text-white ms-2"
            :disabled="!answers[element.nodeName].value"
          >
            {{ translations['file-upload-clear-button'] || 'Clear' }}
          </button>
        </span>
      </div>
      <p>
        <em>{{ translations['file-upload-max-filesize'] }} <strong>{{ maxFileSizeReadable }}</strong></em>
      </p>
      <div
        class="my-3"
        v-if="file"
      >
        <img
          v-if="preview"
          :src="preview"
          class="image-preview d-block mb-2"
          title="Preview of the image"
          alt="Preview image"
        />
        <div
          class="badge bg-light text-dark"
        >
          Size: {{ fileSize }} Kb
        </div>
      </div>
    </div>
    <!-- For all other one line inputs -->
    <input
      v-else
      v-model="answers[element.nodeName].value"
      class="form-control shadow-light"
      :class="v$.$dirty && v$.elementValue.$invalid ? 'border-red' : 'border-green'"
      :placeholder="translations['input-placeholder']"
      :name="element.nodeName"
    />
    <div
      v-if="v$.$dirty && v$.elementValue.$invalid"
      class="fst-italic text-red text-smaller"
    >
      {{ v$.$errors[0].$message }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { email, decimal, required, helpers } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import fileDb from '@/core/FileDb'

export default defineComponent({
  name: 'InputElement',
  inject: [
    'Api',
    'Config'
  ],
  data () {
    return {
      file: null,
      preview: null,
      usingCachedFile: false
    }
  },
  props: {
    currentPageNumber: Number,
    elementIndex: Number,
    element: {
      type: Object,
      required: true
    }
  },
  setup: () => ({
    v$: useVuelidate()
  }),
  validations () {
    let rules = {}
    if (this.element.required) {
      rules = {
        ...rules,
        required: helpers.withMessage(this.translations['required-validation-error'], required)
      }
    }
    switch (this.element.subType) {
      case 'number':
        rules = {
          ...rules,
          // NOTE: Number may be split into decimal and integer type in the future
          decimal: helpers.withMessage(this.translations['decimal-validation-error'], decimal)
        }
        break
      case 'email':
        rules = {
          ...rules,
          email: helpers.withMessage(this.translations['email-validation-error'], email)
        }
        break
      case 'file':
        rules = {
          ...rules,
          fileSizeValidator: helpers.withMessage(this.translations['file-too-large-error'], this.fileSizeValidator),
          fileExtensionValidator: helpers.withMessage(`${this.translations['file-type-not-allowed-error']}: ${this.Config.file.allowedFileExtensions.join(', ')}`, this.fileExtensionValidator),
          fileUniqueFilenameValidator: helpers.withMessage(this.translations['file-filename-must-be-unique-error'], this.fileUniqueFilenameValidator)
        }
        break
    }
    return {
      elementValue: rules
    }
  },
  watch: {
    elementValue () {
      /**
      * Due to our 'unique' setup of computed values, Vuelidate doesn't directly mutate
      * the model, it's inferred via an intermediary, and as such a manual touch
      * upon value change is required for the $dirty state to set correctly.
      */
      this.v$.elementValue.$touch()
    }
  },
  async mounted () {
    await this.loadFileFromCache()
    this.v$.$touch()
  },
  computed: {
    ...mapGetters([
      'answers',
      'translations'
    ]),
    elementValue () {
      if (this.element.nodeName in this.answers) {
        return this.answers[this.element.nodeName].value
      } else {
        return ''
      }
    },
    fileSize () {
      if (this.file) {
        return Math.round(this.file.size * 1000 / this.Config.file.megaByteInBytes)
      } else {
        return null
      }
    },
    // NOTE: Filesize is always read in bytes.
    maxFileSizeReadable () {
      return Math.round(this.Config.file.maxSizeInBytes / this.Config.file.megaByteInBytes) + ' Mb'
    }
  },
  methods: {
    ...mapActions([
      'storeError',
      'clearErrors'
    ]),
    async loadFileFromCache () {
      const cachedFile = await fileDb.get(this.element.nodeName)

      if (cachedFile) {
        const fileName = this.answers[this.element.nodeName].value
        if (cachedFile.name !== fileName) {
          await this.clearCache()
          return
        }

        this.usingCachedFile = true
        this.file = cachedFile
        this.loadFilePreview()
      }
    },
    getFile () {
      return this.file
    },
    async clearCache () {
      await fileDb.delete(this.element.nodeName)
    },
    clearFile () {
      this.usingCachedFile = false
      this.preview = null
      this.file = null
      this.answers[this.element.nodeName].value = null
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = ''
      }
      this.clearCache()
    },
    getFileExtension (fileName) {
      return fileName.toLowerCase().split('.').pop()
    },
    loadFilePreview () {
      if (!this.file.type.startsWith('image')) {
        return
      }

      const fileReader = new FileReader()
      fileReader.readAsDataURL(this.file)
      fileReader.onload = e => {
        const fileContents = e.target.result
        this.preview = fileContents
      }
    },
    fileUniqueFilenameValidator () {
      try {
        const filename = this.answers[this.element.nodeName].value
        if (!filename) {
          return true
        }

        return !Object.values(this.answers)
          .filter(a => a.questionSubType === 'file' && a.nodeName !== this.element.nodeName)
          .some(a => a.value === filename)
      } catch (error) {
        console.error(error)
        return true
      }
    },
    fileSizeValidator () {
      if (!this.file) {
        return true
      }

      if (this.file.size > this.Config.file.maxSizeInBytes) {
        return false
      }
      return true
    },
    fileExtensionValidator () {
      if (!this.file) {
        return true
      }
      const fileExtension = this.getFileExtension(this.file.name)
      if (!this.Config.file.allowedFileExtensions.includes(fileExtension)) {
        return false
      }
      return true
    },
    async uploadFile (ev) {
      try {
        this.preview = null
        this.v$.$touch()
        await this.clearErrors()
        if (ev.target.files[0]) {
          const file = ev.target.files[0]
          this.answers[this.element.nodeName].value = file.name
          this.file = file
          await fileDb.set(this.element.nodeName, file)
          this.loadFilePreview()
        }
      } catch (e) {
        this.storeError({
          type: 'fatal',
          message: e.message
        })
      }
    }
  }
})
</script>
<style type="less" scoped>
  .image-preview {
    max-width: 100px;
  }
</style>
