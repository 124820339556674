<template lang="html">
  <div class="my-3">
    <!-- Provide an English fallback if no completion text is available -->
    <h2 v-if="!this.data">
      {{ translations['complete-title'] || 'Completed' }}
    </h2>
    <p v-html="completedText"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Completed',
  inject: [
    'Marked',
    'DOMPurify'
  ],
  props: {
    data: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'translations'
    ]),
    completedText () {
      let text = ''
      if (!this.data) {
        text = this.translations['complete-text'] || 'Thank you for answering this survey.'
      } else {
        text = this.data
      }
      return this.DOMPurify.sanitize(this.Marked(text))
    }
  }
})
</script>
