class Answer {
    id;
    answerId;
    deliveryId;
    nodeName;
    questionText;
    value;
    questionType;
    questionSubType;
    constructor(id, answerId, deliveryId, nodeName, questionText, value, questionType, questionSubType) {
        this.id = id;
        this.answerId = answerId;
        this.deliveryId = deliveryId;
        this.nodeName = nodeName;
        this.questionText = questionText;
        this.value = value;
        this.questionType = questionType;
        this.questionSubType = questionSubType;
    }
}
export default Answer;
