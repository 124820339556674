<template>
  <div
    v-if="msg && msg.length && variant"
    class="message pe-3 mb-0"
    :class="[
      variant === 'danger' ? 'shake' : '',
      dismissable === true ? 'd-flex' : '',
      `alert alert-${variant} alert-dismissible fade show`
    ]"
    role="alert"
  >
    <div>
      <font-awesome-icon
        :icon="['fas', alertIcon]"
        class="me-1"
        fixed-width
      />
      <span v-html="DOMPurify.sanitize(msg)"></span>
    </div>
    <button
      v-if="dismissable"
      @click="clearMessage()"
      type="button"
      class="btn btn-default p-0 ms-auto"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">
        <font-awesome-icon
          :icon="['fas', 'xmark']"
          class="me-1"
          fixed-width
        />
      </span>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
export default defineComponent({
  name: 'Message',
  data () {
    return {
      msg: ''
    }
  },
  inject: [
    'DOMPurify'
  ],
  props: {
    message: {
      type: String,
      default: '',
      required: true
    },
    variant: {
      type: String,
      default: 'info',
      required: true
    },
    dismissable: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    message (v) {
      this.msg = v
    }
  },
  mounted () {
    this.msg = this.message
  },
  computed: {
    alertIcon () {
      let iconName
      switch (this.variant) {
        case 'danger':
          iconName = 'exclamation-triangle'
          break
        case 'success':
          iconName = 'check-circle'
          break
        default:
          iconName = 'circle-info'
          break
      }
      return iconName
    }
  },
  methods: {
    ...mapActions([
      'clearErrors'
    ]),
    // Clears messages and any set errors
    clearMessage () {
      this.clearErrors()
      this.msg = ''
    }
  }
})
</script>

<style scoped>
  .message {
    z-index: 10
  }
</style>
