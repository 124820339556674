<template>
  <div>
    <div class="progress">
      <div
        :style="themeStyle"
        class="progress-bar progress-bar-bg"
        role="progressbar"
        :aria-valuenow="current"
        :aria-valuemin="1"
        :aria-valuemax="total + 1"
      >
        {{ progressWidth }}%
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { calculateTextColorFromBgColor } from '@/core/Utils'

export default defineComponent({
  name: 'PageIndicator',
  props: {
    current: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    themeColor: {
      type: String,
      default: '#02a5e2',
      required: true
    }
  },
  computed: {
    progressWidth () {
      return Math.round(((this.current + 1) / (this.total)) * 100)
    },
    themeStyle () {
      return {
        '--theme-color': this.themeColor,
        '--width': this.progressWidth + '%',
        '--theme-text-color': calculateTextColorFromBgColor(this.themeColor)
      }
    }
  }
})
</script>

<style type="less" scoped>
  .progress {
    height: 18px;
  }
  .progress-bar {
    color: var(--theme-text-color);
  }
  .progress-bar-bg {
    background-color: var(--theme-color);
    width: var(--width);
  }
</style>
