export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        }
        : null;
}
export function calculateTextColorFromBgColor(bgHex) {
    const c = hexToRgb(bgHex);
    if (!c) {
        return '#000000';
    }
    const { r, g, b } = c;
    if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
        return '#000000';
    }
    else {
        return '#ffffff';
    }
}
