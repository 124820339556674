export const requiredTranslationKeys = [
    'user-terms-title',
    'survey-please-wait',
    'complete-title',
    'survey-introduction-text',
    'complete-send-me-confirmation',
    'receipt-sent',
    'complete-text',
    'navigation-send',
    'invalid-email-provided',
    'navigation-previous',
    'privacy-policy-title',
    'answer-all-questions-before-moving-on'
];
