<template>
  <div
    class="send-receipt-container d-flex col-12 align-items-center"
  >
    <div
      v-if="!receiptSent"
      class="form-check col-12 col-md-3"
    >
      <input
        v-model="sendReceipt"
        :disabled="loading || this.isPreview"
        class="form-check-input"
        type="checkbox"
        id="send-receipt"
      >
      <label
        class="form-check-label"
        for="send-receipt"
      >
        <div v-if="'complete-send-me-confirmation' in translations">
          {{ DOMPurify.sanitize(translations['complete-send-me-confirmation']) }}
        </div>
        <div v-else>
          Send me a receipt
        </div>
      </label>
    </div>
    <div
      v-if="sendReceipt === true"
      class="col-12 col-md-9 d-flex mt-2"
    >
      <div
        v-if="receiptSent"
      >
        <font-awesome-icon
          :icon="['fas', 'check-circle']"
          class="ms-1 text-green"
          fixed-width
        >
        </font-awesome-icon>
        {{ translations['receipt-sent'] || 'A receipt was sent to' }}: {{ emailAddress }}
      </div>
      <div
        v-else
        class="input-group"
      >
        <input
          v-model="emailAddress"
          @keydown.enter="sendEmail()"
          @blur="v$.emailAddress.$model.length ? v$.emailAddress.$touch() : ''"
          :placeholder="'email' in translations ? translations['email'] : 'Email...'"
          :disabled="loading"
          :class="v$.emailAddress.$error ? 'border-red' : ''"
          type="email"
          class="form-control"
          aria-label="Email to send the receipt to"
          aria-describedby="send-receipt-button"
        >
        <button
          @click="sendEmail()"
          :disabled="v$.emailAddress.$invalid || loading"
          class="btn btn-sm btn-primary bg-blue input-group-text"
          id="send-receipt-button"
        >
          <font-awesome-icon
            :icon="['fas', loading === true ? 'circle-notch' : 'paper-plane']"
            class="me-1"
            :spin="loading === true"
            fixed-width
          >
          </font-awesome-icon>
          {{ DOMPurify.sanitize(translations['navigation-send'] || 'Send') }}
        </button>
      </div>
      <div
        v-if="v$.emailAddress.$dirty && v$.emailAddress.$invalid"
        class="text-red text-smaller fst-italic position-absolute"
      >
        {{ DOMPurify.sanitize(translations['invalid-email-provided'] || 'Invalid email provided') }}.
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { required, email } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapActions, mapGetters } from 'vuex'
export default defineComponent({
  name: 'SendReceipt',
  inject: [
    'Api',
    'DOMPurify'
  ],
  setup: () => ({
    v$: useVuelidate()
  }),
  validations () {
    return {
      emailAddress: {
        required,
        email
      }
    }
  },
  props: {
    surveyKey: {
      type: String,
      default: '',
      required: true
    },
    responseKey: {
      type: String,
      default: '',
      required: true
    },
    isPreview: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      sendReceipt: false,
      emailAddress: '',
      loading: false,
      receiptSent: false
    }
  },
  watch: {
    emailAddress (v) {
      if (!v.length) {
        this.v$.emailAddress.$reset()
      }
    }
  },
  computed: {
    ...mapGetters([
      'translations'
    ])
  },
  methods: {
    ...mapActions([
      'storeError'
    ]),
    async sendEmail () {
      this.v$.emailAddress.$touch()
      if (!this.v$.emailAddress.$error) {
        try {
          this.loading = true
          const sendResult = await this.Api.response.sendReceipt(this.surveyKey, this.responseKey, this.emailAddress)
          if (sendResult.status === 204) {
            this.receiptSent = true
          } else {
            throw new Error(sendResult.statusText)
          }
        } catch (Error) {
          this.storeError({
            type: 'fatal',
            message: Error.message
          })
        } finally {
          this.loading = false
        }
      }
    }
  }
})
</script>
<style scoped>
  .send-receipt-container {
    flex-wrap: wrap;
  }
</style>
