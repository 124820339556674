<!-- Question Grids -->
<template lang="html">
  <div class="table-responsive mt-2">
    <table
      class="table table-responsive-stack table-borderless"
      :aria-label="`Question ${elementIndex + 1}: ${element.data.text} on page ${currentPageNumber + 1}`"
    >
      <thead
        class="d-none d-md-table-cell"
      >
        <tr class="fw-normal sm-border">
          <th
            scope="col"
            class="w-flex-4"
          >
          <!-- Empty x/y intersection cell -->
          </th>
          <th
            v-for="(item, itemIndex) in choiceHeadings"
            :key="`heading-${itemIndex}`"
            scope="col"
            class="text-center fw-normal text-smaller"
          >
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody
        v-if="element.data.ranges"
      >
        <tr
          v-for="(question, questionIndex) in element.data.choices.data"
          :key="`grid-question-${elementIndex}-${questionIndex}`"
        >
          <th
            scope="row"
            class="col w-flex-4 fw-normal ps-0"
          >
            <div class="d-flex">
              {{ question.text }}
              <span
                v-if="element.required === true"
                title="This question is required"
                class="text-smaller text-red"
              >
                <font-awesome-icon
                  :icon="['fas', 'asterisk']"
                  class="mb-1 required fa-2xs"
                />
              </span>
            </div>
          </th>
          <td
            v-for="(range, rangeIndex) in element.data.ranges.data"
            :key="`grid-question-${elementIndex}-${questionIndex}-range-value-${rangeIndex}`"
            class="text-md-center"
          >
            <div v-if="!range.nodeName">
              This range item has a <code>NULL</code> nodename!
            </div>
            <label
              v-else
              class="d-block pointer"
            >
              <input
                v-model="answers[question.nodeName].value"
                @change="updateDependencyTree(range, element, currentPageNumber, element.elementIndex)"
                type="radio"
                :value="range.text"
                :name="question.nodeName"
              />
              <span class="d-md-none ms-3">
                {{ range.text }}
              </span>
            </label>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <td class="text-red">
          Invalid setup. This question grid has no range values!
        </td>
      </tbody>
    </table>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
export default defineComponent({
  name: 'GridElement',
  data () {
    return {
    }
  },
  props: {
    currentPageNumber: Number,
    elementIndex: Number,
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'answers'
    ]),
    /**
    * A list of values used for the heading. Since all values are the same
    * in a choice range grid, we can just use the first entry for headings
    */
    choiceHeadings () {
      const headings = this.element.data.ranges.data
      if (Array.isArray(headings) && headings.length) {
        return headings.map(e => {
          return {
            value: e.value,
            text: e.text
          }
        })
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions([
      'storeDependencyTree'
    ]),
    /**
    * A convenience method for selecting all choice range options to one value.
    * Useful when testing many surveys with several choice range grids
    */
    setAll (value) {
      // Get nodeNames of each question in the grid
      const nodeNames = this.element.data.choices.data.map(e => e.nodeName)
      nodeNames.forEach(nodeName => {
        if (nodeName in this.answers) {
          // Map each value to the correct nodeName in the answer model
          this.answers[nodeName].value = value
        }
      })
    },
    updateDependencyTree (option, element, pageIndex, elementIndex) {
      this.storeDependencyTree({
        id: option.id,
        value: option.value !== null ? option.value : option.text,
        type: element.type,
        subType: element.subType,
        nodeName: option.nodeName,
        pageIndex: pageIndex,
        elementIndex: elementIndex
      })
    }
  }
})
</script>
<style scoped>

.table thead>tr.sm-border.sm-border>th {
  border-bottom: 0.1rem solid #dee2e6;
}
</style>
