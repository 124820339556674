<template>
  <Popper
      placement="top"
      arrow
      locked
      :show="showTooltip"
      :disabled="disabled"
      @mouseenter="setTooltipVisibility(true)"
      @mouseleave="setTooltipVisibility(false)"
  >
      <slot></slot>
      <template #content>
        <slot name="content">
            <div>{{ content }}</div>
        </slot>
      </template>
  </Popper>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import Popper from 'vue3-popper'

const props = defineProps({
  content: String,
  disabled: {
    type: Boolean,
    default: false
  }
})

const showTooltip = ref(false)

function setTooltipVisibility (value) {
  showTooltip.value = value
}

</script>

<style>
  :root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>
