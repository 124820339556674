import DOMPurify from 'dompurify';
const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';
const appendSecureRelValue = (rel) => {
    const attributes = new Set(rel ? rel.toLowerCase().split(' ') : []);
    attributes.add('noopener');
    attributes.add('noreferrer');
    return Array.from(attributes).join(' ');
};
DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
    if (node.tagName === 'A' && node.hasAttribute('target') && node.getAttribute('target') === '_blank') {
        node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'));
    }
});
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
        node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE));
        node.removeAttribute(TEMPORARY_ATTRIBUTE);
        if (node.getAttribute('target') === '_blank') {
            const rel = node.getAttribute('rel');
            node.setAttribute('rel', appendSecureRelValue(rel));
        }
    }
});
export default DOMPurify;
