<template>
  <div
    class="survey-container shadow mt-3 px-4 py-3 bg-white rounded"
    :class="errorsExist ? 'mt-5' : ''"
  >
    <!-- Survey background placeholder -->
    <div
      class="survey-background"
      :class="surveyBackground ? 'slow-fade-in' : ''"
      :style="`background-image: url(${surveyBackground})`"
    >
    </div>
    <div v-if="loading">
      <!-- Content placeholders -->
      <Placeholder />
      <Loading />
    </div>
    <transition
      v-else-if="currentSurveyPage && !errorLoadingSurvey"
      name="fade"
      appear
    >
      <div v-if="(surveyStarted && !surveyEnded) || preview"  class="w-100">
        <Message
          v-if="preview"
          :message="previewMinified === true ? translations['preview-enabled'] : translations['survey-preview-warning']"
          variant="info"
          class="text-center bg-blue text-white text-smaller position-fixed top-right pointer"
          title="Click to toggle preview info."
          @click="previewMinified = !previewMinified"
        />
        <div>
          <Header
            :logoOnly="!isStartpage"
            :data=survey
          ></Header>
        </div>
        <!-- Response meta data if the survey has already been completed -->
        <Message
          v-if="response && response.endDate"
          :message="`You completed this survey on: ${ new Date(response.endDate).toLocaleDateString() }`"
          :dismissable="true"
          variant="info"
          class="alert alert-info text-center"
        />
        <!-- Display if presenting the first page and survey isn't completed -->
        <Introduction
          v-if="!surveyCompleted && currentFilteredPageNumber === 0 && !displayPrivacyPolicy"
          :data="survey.intro"
        />
        <div
          class="d-flex align-items-start"
        >
          <!-- Survey Elements -->
          <div
            v-if="!surveyCompleted && !displayPrivacyPolicy && currentFilteredPageNumber in surveyPagesFiltered"
            class="col-12"
          >
            <div
              class="col-12 d-flex flex-wrap justify-content-between"
            >
              <!-- Element main container -->
              <template v-for="(element, elementIndex) in currentSurveyPage.elements"
                :key="`survey-page-${currentFilteredPageNumber}-element-${elementIndex}`">
                <div
                  class="mb-2 width-sm-100"
                  :class="element.settings.width === 100
                    ? 'w-100'
                    : element.settings.width === 75
                    ? 'w-75'
                    : element.settings.width === 50
                    ? 'w-50'
                    : element.settings.width === 25
                    ? 'w-25'
                    : ''
                  "
                >
                  <!-- Not questions, hence decorational elements -->
                  <Decoration
                    v-if="!element.isQuestion"
                    :element="element"
                    :themeHeaderColor="surveyThemeColor"
                  />
                  <!-- Element is of a question type -->
                  <div
                    v-else
                    class="pe-3 mb-3"
                  >
                    <!-- Question text / header -->
                    <h6 class="d-flex align-items-center">
                      {{ element.data.text }}
                      <span
                        v-if="element.required === true && element.type !== 'grid'"
                        title="This question is required"
                        class="text-smaller text-red"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'asterisk']"
                          class="mb-1 fa-2xs"
                        />
                      </span>
                    </h6>
                    <!-- Ingress, if present -->
                    <div
                      v-if="element.data.ingress"
                      class="fst-italic my-2"
                    >
                      {{ element.data.ingress }}
                    </div>
                    <!-- Text (one-line) -->
                    <Input
                      v-if="element.type === 'input'"
                      :element="element"
                      :currentPageNumber="currentFilteredPageNumber"
                      :elementIndex="elementIndex"
                      :ref="element.nodeName"
                    />
                    <!-- Textarea (multiple lines) -->
                    <Textarea
                      v-if="element.type === 'textarea'"
                      :element="element"
                      :currentPageNumber="currentFilteredPageNumber"
                      :elementIndex="elementIndex"
                    />
                    <!-- Multiple choice elements -->
                    <MultipleChoice
                      v-if="element.type === 'multiple'"
                      :element="element"
                      :currentPageNumber="currentFilteredPageNumber"
                      :elementIndex="elementIndex"
                    />
                    <!-- Choice Range Grids -->
                    <Grid
                      v-if="element.type === 'grid'"
                      :element="element"
                      :currentPageNumber="currentFilteredPageNumber"
                      :elementIndex="elementIndex"
                    />
                  </div>
                </div>
            </template>
            </div>
          <!-- Answers end -->
          </div>
          <!-- Display Privacy Policy or Summary Page if there is no next page -->
          <PrivacyPolicy
            v-if="surveyCollectsPersonalInformation && displayPrivacyPolicy"
            :name="survey.privacyPolicyName"
            :slug="survey.privacyPolicySlug"
            :content="survey.privacyPolicyContent"
            :questionsCollectingPersonalInformation="questionsCollectingPersonalInformation"
          />
          <!-- Display survey completed texts and the option to receive receipt -->
          <div
            v-if="surveyCompleted"
            class="col-12"
          >
            <Completed
              :data="survey.outro"
              class="mb-4"
            />
            <hr />
            <SendReceipt
              :surveyKey="surveyKey"
              :responseKey="responseKey"
              :isPreview="preview"
            />
          </div>
        </div>
        <!-- Page navigation -->
        <div
          v-if="!surveyCompleted"
        >
          <PageIndicator
            :current="currentFilteredPageNumber"
            :total="calculatedSurveyLength"
            :theme-color="surveyThemeColor"
          />
          <div
            class="d-flex justify-content-center align-items-center mt-3"
          >
            <button
              v-if="previousPageExists"
              @click="navigate('prev')"
              class="btn btn-light me-2"
              :disabled="postingAnswers"
            >
              <font-awesome-icon
                icon="chevron-left"
                class="me-2"
              >
              </font-awesome-icon>
              {{ translations['navigation-previous'] || 'Previous' }}
            </button>
            <Tooltip :disabled="!formHasErrors">
              <template #content>
                <div>
                  <font-awesome-icon
                    :icon="['fas', 'circle-info']"
                    class="me-1"
                    fixed-width
                  />
                  {{ translations['answer-all-questions-before-moving-on'] || 'Please answer all questions before moving on.' }}
                </div>
              </template>
              <!-- Except when the privacy policy is shown, the next button acts as both progress and complete -->
              <button
                v-if="!displayPrivacyPolicy"
                ref="nextButton"
                @click="navigate('next')"
                :style="themeColorStyle"
                class="btn survey-theme-color"
                :disabled="formHasErrors || loading || postingAnswers"
              >
                {{ nextPageExists ? translations['navigation-next'] || 'Next'
                  : surveyCollectsPersonalInformation ? translations['privacy-policy-title'] || 'Privacy Policy'
                  : translations['navigation-send'] || 'Send'
                }}
                <font-awesome-icon
                  :icon="['fas', (nextPageExists || surveyCollectsPersonalInformation) ? 'chevron-right' : 'check']"
                  class="ms-2"
                  hover
                >
                </font-awesome-icon>
              </button>
              <!-- For surveys collecting personal information, the "accept policy" is shown -->
              <button
                v-else
                class="btn survey-theme-color text-white"
                :style="themeColorStyle"
                @click="completeSurvey()"
              >
                {{ translations['accept-and-complete'] || 'Accept and complete' }}
                <font-awesome-icon
                  icon="check"
                  class="ms-2"
                >
                </font-awesome-icon>
              </button>
            </Tooltip>
            <font-awesome-icon
              v-if="postingAnswers"
              icon="circle-notch"
              class="ms-1"
              spin
            >
            </font-awesome-icon>
          </div>
          <Message
            v-if="formHasErrors"
            :message="translations['answer-all-questions-before-moving-on']"
            variant="default"
            class="text-center my-1 text-smaller"
          />
        </div>
      </div>
      <!-- If survey has not started or ended, show message -->
      <div v-else-if="surveyEnded" class="mt-3">
        <h2> {{ this.survey.title }} </h2>
        <h3> {{ translations['thanks-for-interest'] || 'Thank you for your interest!' }} </h3>
        <p>
          {{ translations['survey-ended-text'] || 'This survey has ended.' }}
        </p>
      </div>
      <div v-else-if="!surveyStarted && !surveyEnded" class="mt-3">
        <h3> {{ translations['thanks-for-interest'] || 'Thank you for your interest!' }} </h3>
        <p>
          {{ translations['survey-starts'] || 'This survey starts ' }} {{ startDateFormatted }}
        </p>
      </div>
    </transition>
    <!-- Render error message that something is amiss with the survey -->
    <div
      v-else
      class="mt-3">
      <h3>We've discovered some issues</h3>
      <p>
        One or more errors are preventing this survey from being rendered.
      </p>
      <p
        v-if="environment !== 'production'"
        class="mt-3"
      >
         Please use the dev console for further information.
      </p>
    </div>
    <Message
      v-if="!loading && errorsExist"
      :variant="errors.type === 'fatal' ? 'danger' : 'info'"
      :message="errors.message"
      :dismissable="true"
      class="position-fixed position-top"
    />
    <!-- Insight info -->
    <Insight
      v-if="environment !== 'production'"
      :data="insightInfo"
    />
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Ajv2020 from 'ajv/dist/2020'
import Answer from '@/Entities/Answer'
import Completed from '@/components/Completed'
import date from 'date-and-time'
import Decoration from '@/components/Elements/Decoration'
import Grid from '@/components/Elements/Grid'
import Header from '@/components/Common/Header'
import Input from '@/components/Elements/Input'
import Insight from '@/components/Insight'
import Introduction from '@/components/Introduction'
import Loading from '@/components/Common/Loading'
import Message from '@/components/Common/Message'
import MultipleChoice from '@/components/Elements/MultipleChoice'
import PageIndicator from '@/components/Common/PageIndicator'
import Placeholder from '@/components/Common/Placeholder'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import { requiredTranslationKeys } from '@/core/RequiredTranslationKeys'
import SendReceipt from '@/components/SendReceipt'
import SurveySchema from '@/assets/schema/survey.json'
import Textarea from '@/components/Elements/Textarea'
import { defineComponent } from 'vue'
import { integer, required } from '@vuelidate/validators'
import { mapGetters, mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import Footer from '@/components/Common/Footer'
import { calculateTextColorFromBgColor } from '@/core/Utils'

export default defineComponent({
  name: 'Index',
  inject: [
    'Api'
  ],
  components: {
    Completed,
    Decoration,
    Grid,
    Header,
    Input,
    Insight,
    Introduction,
    Loading,
    Message,
    MultipleChoice,
    PageIndicator,
    Placeholder,
    PrivacyPolicy,
    SendReceipt,
    Textarea,
    Footer
  },
  data () {
    return {
      loading: false,
      postingAnswers: false,
      errorLoadingSurvey: false,
      survey: null,
      surveyLoaded: false,
      surveyCompleted: false,
      response: [],
      dateFormatter: Date,
      currentFilteredPageNumber: 0,
      dependenciesSelected: [],
      environment: process.env.NODE_ENV,
      displayPrivacyPolicy: false,
      previewMinified: true,
      answerModel: [],
      currentPage: 0
    }
  },
  setup: () => ({
    v$: useVuelidate()
  }),
  validations: {
    answers: {
      required,
      $each: {
        value: {
          required,
          integer
        },
        type: {
          required,
          integer
        },
        nodeName: {
          required
        },
        questionText: {
          required,
          integer
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'answers',
      'dependencyTree',
      'errors',
      'surveys',
      'translations'
    ]),
    errorsExist () {
      if (this.errors !== undefined) {
        return Object.keys(this.errors).length > 0
      } else {
        return false
      }
    },
    insightInfo () {
      // This information is only available in development environments
      if (this.environment !== 'production' && this.currentSurveyPage) {
        return {
          structures: {
            requiredQuestionsOnPage: this.requiredQuestionsOnPage,
            questionsThatCollectPersonalInformation: this.questionsCollectingPersonalInformation,
            currentSurveyPage: this.currentSurveyPage,
            answerModel: this.answers,
            dependencyTree: this.dependencyTree,
            surveyMapped: this.surveyMapped,
            existingAnswers: this.response.answers
          },
          vitals: {
            currentPageNumber: this.currentFilteredPageNumber,
            calculatedSurveyLength: this.calculatedSurveyLength,
            surveyCollectsPersonalInformation: this.surveyCollectsPersonalInformation,
            isAnonymous: this.surveyType,
            requiredQuestionsOnPageAnswered: this.requiredQuestionsOnPageAnswered
          }
        }
      } else {
        return {}
      }
    },
    requiredQuestionsOnPage () {
      let requiredQuestions = []
      if (Array.isArray(this.currentSurveyPage.elements)) {
        /**
        * Includes all required questions on the current page,
        * including choice range grids. Those elements are special, and if
        * encountered, they must be handled separately
        */
        this.currentSurveyPage.elements
          .filter(e => e.required === true)
          .forEach(e => {
            if (e.type === 'grid') {
              /**
              * Choice Range Grids are a collection of multiple choice questions.
              * As such, we do not map the root nodename, but instead the nodeName
              * of each choice instead
              */
              const choices = e.data.choices.data
              // Only merge required branch if there are actual choices present
              if (Array.isArray(choices) && choices.length) {
                // Merge grid nodenames with the rest of the required questions
                requiredQuestions = requiredQuestions.concat(choices)
              }
            } else {
              requiredQuestions.push(e)
            }
          })
      }
      return requiredQuestions.map(e => e.nodeName)
    },
    requiredQuestionsOnPageAnswered () {
      // There are no required questions, so this check is done
      if (!this.requiredQuestionsOnPage.length) {
        return true
      } else {
        return this.requiredQuestionsOnPage.every(e => e in this.answers && this.answers[e].value !== '' && this.answers[e].value !== null)
      }
    },
    answeredQuestionsAreValid () {
      return !this.v$.$invalid
    },
    formHasErrors () {
      return !this.requiredQuestionsOnPageAnswered || !this.answeredQuestionsAreValid
    },
    preview () {
      // Query parameter for preview. I.e preview=1 to enable.
      return this.$route.query.preview === '1'
    },
    surveyKey () {
      return this.$route.params.surveyKey
    },
    responseKey () {
      if (this.surveys && this.surveys[this.surveyKey] !== undefined) {
        return this.surveys[this.surveyKey].responseKey
      } else {
        return null
      }
    },
    surveyType () {
      if (this.survey && this.survey.isAnonymous !== undefined) {
        return this.survey.isAnonymous
      } else {
        return null
      }
    },
    startDateFormatted () {
      if (this.survey) {
        var startDate = new Date(this.survey.startDate)
        return date.format(startDate, 'DD.MM.YYYY HH:mm')
      } else {
        return ''
      }
    },
    endDateFormatted () {
      if (this.survey) {
        if (!this.survey.endDate) {
          return this.$t('neverEnds')
        } else {
          const endDate = new Date(this.survey.endDate)
          return date.format(endDate, 'DD.MM.YYYY HH:mm')
        }
      } else {
        return ''
      }
    },
    surveyEnded () {
      if (this.survey && this.survey.endDate) {
        var today = new window.Date()
        var endDate = new window.Date(this.survey.endDate)
        return endDate.getTime() < today.getTime()
      } else {
        return false
      }
    },
    surveyStarted () {
      if (this.survey && this.survey.startDate) {
        var today = new window.Date()
        var startDate = new window.Date(this.survey.startDate)
        return startDate.getTime() <= today.getTime()
      } else {
        return false
      }
    },
    surveyThemeColor () {
      if (this.survey && 'themeColor' in this.survey && this.survey.themeColor) {
        return this.survey.themeColor
      } else {
        return '#02a5e2'
      }
    },
    surveyTextColor () {
      return calculateTextColorFromBgColor(this.surveyThemeColor)
    },
    themeColorStyle () {
      return {
        '--survey-theme-color': this.surveyThemeColor,
        '--survey-text-color': this.surveyTextColor
      }
    },
    calculatedSurveyLength () {
      if (this.surveyPagesFiltered) {
        return this.surveyPagesFiltered.length
      } else {
        return null
      }
    },
    surveyPagesFiltered () {
      /**
      * surveyDependencyMap is a calculated up-to-date mapping of the survey
      * structure reflecting elements.
      */
      if (this.surveyDependencyMap && Array.isArray(this.surveyDependencyMap)) {
        const res = this.surveyDependencyMap.map((elements, index) => {
          return {
            pageNumber: index,
            elements
          }
        }).filter(page => page.elements.length)
        return (res && res.length) ? res : []
      } else {
        return []
      }
    },
    surveyDependencyMap () {
      if (Array.isArray(this.surveyStructure)) {
        /**
        * Survey Structure with all elements (and their pages) with no
        * dependencies. The survey will always be as least this long.
        * We preserve pages to show the correct count.
        */
        return this.surveyStructure.map(e => e.filter(i => {
          if (i.settings.dependantOn.length) {
            /**
            * Pages with elements that have dependencies. They'll be included
            * if any of the selected values they depend on are present in the
            * dependencyTree. e.g a question can have one dependency from several questions, but only needs one of them to be checked.
            * This is why we use some() here.
            * We should probably have some more functionality to this down the line, with checking e.g question types and filter dependencies, and then decide if we they should be filtered by some() or every() e.g.
            * For example in the survey editor we can select all answers from a multiple choice type question as dependencies for a single question, but it is not possible to select all answers from a grid question as dependencies for a single question.
            */
            return i.settings.dependantOn.some(d => d in this.dependencyTree)
          } else {
            /**
            * Pages with elements that have no dependencies. The survey will
            * at least be this long.
            */
            return i
          }
        }))
      } else {
        return null
      }
    },
    nextPageExists () {
      return ((this.currentFilteredPageNumber + 1) in this.surveyPagesFiltered)
    },
    previousPageExists () {
      return ((this.currentFilteredPageNumber - 1) in this.surveyPagesFiltered)
    },
    isStartpage () {
      return this.currentFilteredPageNumber === 0 && !this.displayPrivacyPolicy
    },
    participantKey () {
      return this.$route.params.participantKey
    },
    // This contains the entire survey page, so non-questions may also be included
    currentSurveyPage () {
      if (this.surveyPagesFiltered && Array.isArray(this.surveyStructure)) {
        return this.surveyPagesFiltered[this.currentFilteredPageNumber]
      } else {
        return null
      }
    },
    /**
    * Returns a list of nodeNames for questions from the currently viewed survey page.
    * This is used to directly access those entries in answers when moving between pages
    *
    * @return Array
    */
    questionsOnCurrentPage () {
      if (Array.isArray(this.currentSurveyPage.elements)) {
        return this.currentSurveyPage.elements.filter(e => e.isQuestion === true && e.nodeName !== null).map(e => e.nodeName)
      } else {
        return []
      }
    },
    /**
    * Returns an array of elements that correspond to questions that collect
    * personal information in the entire survey. This is used in conjunction
    * with privacy policy.
    *
    * @return Array
    */
    questionsCollectingPersonalInformation () {
      if (Object.keys(this.surveyMapped).length > 0) {
        /**
        * With object entries, e[0] will be the nodeName, while e[1] will
        * contain the object properties themselves.
        */
        return Object.entries(this.surveyMapped)
          // Filter out elements that are questions and collect personal information
          .filter(e => e[1].isQuestion === true && e[1].isPersonalInformation === true)
          // Map the q[1] (object properties) of the results to an array
          .map(q => q[1])
      } else {
        return []
      }
    },
    /**
    * Does the survey collect personal information?
    *
    * @return Boolean
    */
    surveyCollectsPersonalInformation () {
      if (Array.isArray(this.questionsCollectingPersonalInformation)) {
        return this.questionsCollectingPersonalInformation.length > 0
      } else {
        return false
      }
    },
    surveyStructure () {
      if (this.survey && 'data' in this.survey && this.survey.data) {
        return this.survey.data
      } else {
        return null
      }
    },
    // Creates a directly accessible mapping to each element in the survey via nodename
    surveyMapped () {
      const output = {}
      if (this.surveyStructure) {
        this.surveyStructure.forEach(page => {
          page.forEach(e => {
            output[e.nodeName] = e
          })
        })
      }
      return output
    },
    surveyBackground () {
      if (this.survey && 'background' in this.survey && this.survey.background) {
        return this.survey.background
      } else {
        return ''
      }
    },
    numberOfPages () {
      if (this.surveyStructure && Array.isArray(this.surveyStructure)) {
        let surveyLength = this.surveyStructure.length - 1
        if (this.surveyCollectsPersonalInformation) {
          surveyLength++
        }
        return surveyLength
      } else {
        return 0
      }
    }
  },
  methods: {
    ...mapActions([
      'storeSurveyMetaData',
      'clearSurveyMetaData',
      'storeSurveyPageIndex',
      'storeError',
      'storeAnswerModel',
      'storeDependencyTree',
      'storeTranslations'
    ]),
    async completeSurvey () {
      try {
        if (!this.preview) {
          // The summary page outlines privacy policy and such
          const res = await this.Api.response.complete(this.responseKey)
          this.postingAnswers = true

          if (res.status === 204) {
            const redirectAddress = this.generateRedirectAddress()
            if (redirectAddress != null) {
              window.location.href = redirectAddress
              return
            }
            this.surveyCompleted = true
            this.displayPrivacyPolicy = false
          } else {
            throw new Error(`The survey could not be finished. ${res.status} - ${res.statusText}`)
          }
        } else {
          this.surveyCompleted = true
          this.displayPrivacyPolicy = false
        }
      } catch (Error) {
        this.storeError({
          type: 'fatal',
          message: Error.message
        })
      } finally {
        this.postingAnswers = false
      }
    },
    shouldBeDisplayed (el) {
      // Check if this element's ID exists in the answer tree
      if (el.settings.dependantOn.length) {
        // This element has dependencies, check if all are met by the values available in dependencyTree
        return el.settings.dependantOn.some(e => e in this.dependencyTree)
      } else {
        // The element has no dependencies, so display element
        return true
      }
    },
    /**
    * Creates an answer model for the entire survey, which is then used to map values from each type of element
    * and existing answers (if present)
    */
    createAnswerModel (surveyElements, existingAnswers) {
      const existingAnswersMapped = {}
      if (existingAnswers.length) {
        existingAnswers.forEach(e => {
          if (e.nodeName !== null) {
            // Convert each existing response to an easily accessible object for later access
            existingAnswersMapped[e.nodeName] = e
            /**
            * NOTE: A backend change here is needed in order to identify what choice represents the value
            * (as opposed to value for the entire question) in order to map them to a dependency
            */
          }
        })
      }
      const surveyAnswerModel = {}
      // Create an answer model that corresponds to the entire survey
      surveyElements.forEach((pageElements, pageIndex) => {
        pageElements.forEach((element, elementIndex) => {
          // Default answer, which is empty
          let answerValue = null
          const elementId = element.id
          if (element.nodeName !== null && element.isQuestion === true) {
            // Map any existing answer (from existing response -> AnswerDtos) to the answer model
            if (element.type === 'grid') {
              // Each grid choice are mapped by using the choice nodeName and not the element's nodeName
              element.data.choices.data.forEach(choice => {
                // Check if there's an existing (previous) answer for this choice range grid choice
                if (existingAnswersMapped[choice.nodeName]) {
                  answerValue = existingAnswersMapped[choice.nodeName].value
                } else {
                  answerValue = null
                }
                const answer = new Answer(elementId, 0, 0, choice.nodeName, '', answerValue, element.type, element.subType)
                surveyAnswerModel[choice.nodeName] = answer
              })
            } else {
              /**
              * For all other elements we use element's root nodeName.
              * Check if there's an existing (previous) answer for this element
              */
              if (existingAnswersMapped[element.nodeName]) {
                answerValue = existingAnswersMapped[element.nodeName].value
              }
              const answer = new Answer(elementId, 0, 0, element.nodeName, '', answerValue, element.type, element.subType)
              surveyAnswerModel[element.nodeName] = answer
            }
            /**
            * This is a special case in which we build the dependencyTree from existing answers.
            * This allows dependencies to work when the survey is displayed, hiding/showing elements based
            * on what the user has answered before.
            * Go to answers[e.nodeName] and then through all that element's choices
            * If a choice with the value from the answer (value) is found, use the ID of the choice
            * and add to dependencyTree.
            * NOTE: Currently only multiple choice elements are supported to control dependencies
            */
            if (element.type === 'multiple') {
              const matching = this.surveyMapped[element.nodeName].data.choices.data.filter(e => e.text === answerValue)
              if (matching.length) {
                // All matches choices needs to be added to the dependencyTree
                matching.forEach(match => {
                  this.storeDependencyTree({
                    id: match.id,
                    value: match.text,
                    type: element.type,
                    subType: element.subType,
                    nodeName: match.nodeName,
                    pageIndex: pageIndex,
                    elementIndex: elementIndex
                  })
                })
              }
            }
          }
        })
      })
      // Save the entire survey's answer model to Vuex, so we can address it at any page / component
      this.storeAnswerModel(surveyAnswerModel)
    },
    async getTranslations (language = 'en') {
      language = language.toLowerCase()
      // Language will fallback to English if no language has been provided (or erronous)
      const res = await import('../assets/lang/' + language + '.json')
      const missingTranslationKeys = []
      // Ensure we have all keys the survey engine require
      requiredTranslationKeys.forEach(e => {
        if (!Object.keys(res).includes(e)) {
          missingTranslationKeys.push(e)
        }
      })
      /**
      * Notify about missing required keys, but let the application still
      * work as we provide fallbacks for missing ones.
      */
      if (missingTranslationKeys.length) {
        console.error('One or more translation keys are missing:')
        console.dir(missingTranslationKeys)
      }

      const translations = {}

      if (language !== 'en') {
        // Load English translations as well, to ensure we have fallbacks
        const en = await import('../assets/lang/en.json')

        // Assign all english translations first
        Object.assign(translations, en)
      }

      // Assign the translations for the selected language
      Object.assign(translations, res)

      this.storeTranslations(translations)
    },
    anyAnswersChanged () {
      // Check if any of the current answers have changed from the previous response
      return Object.values(this.answers).some(a => {
        const prevAnswer = this.response.answers.find(prev => prev.nodeName === a.nodeName)
        const prevAnswerValue = prevAnswer ? prevAnswer.value : null
        return prevAnswerValue !== a.value
      })
    },
    generateRedirectAddress () {
      const redirectAddress = this.survey.redirectAddress
      if (!redirectAddress) {
        return null
      }
      const redirectAddressParams = redirectAddress.match(/\[(.*?)\]/g)
      const availableParams = [
        {
          name: 'SurveyId',
          value: this.survey.surveyId
        },
        {
          name: 'QueryId',
          value: this.survey.id
        },
        {
          name: 'SurveyKey',
          value: this.response.surveyKey
        },
        {
          name: 'QueryKey',
          value: this.response.queryKey
        },
        {
          name: 'ProjectKey',
          value: this.survey.projectKey
        },
        {
          name: 'ParticipantKey',
          value: this.response.participantKey
        },
        {
          name: 'SurveySlug',
          value: this.survey.slug
        }
      ]

      if (!redirectAddressParams) {
        return redirectAddress
      }

      const redirectAddressWithValues = redirectAddressParams.reduce((acc, param) => {
        const paramName = param.replace('[', '').replace(']', '')
        const paramValue = availableParams.find(p => p.name === paramName)
        if (!paramValue) return acc
        return acc.replace(`[${paramName}]`, paramValue.value)
      }, redirectAddress)
      return redirectAddressWithValues
    },
    async navigate (direction = null) {
      try {
        this.$progress.start()
        if (!direction) {
          throw new Error('Invalid direction')
        }
        // Ensure all required questions have answers
        if (direction === 'next' && !this.requiredQuestionsOnPageAnswered) {
          throw new Error('Not all required questions have been answered')
        }

        let adjustment = 0
        switch (direction) {
          case 'next':
            adjustment++
            break
          case 'prev':
            adjustment--
            break
        }
        /**
        * Posts answers upon advancement, not when going backwards.
        */
        if (direction === 'next') {
          /**
          * Contains the nodeNames for the questions on the current page.
          * Each of these will have a corresponding entry in answers
          * (stored in Vuex). This allows us to effectively only run through
          * elements on the current page and not all of them.
          */
          var form = new FormData()
          this.questionsOnCurrentPage.forEach(nodeName => {
            const element = this.surveyMapped[nodeName]
            if (!this.shouldBeDisplayed(element)) {
              return
            }

            /**
            * Surveymapped is the entire survey flattened and accessible by
            * nodename. Check if the current question is a grid.
            */
            if (element.type === 'grid') {
              // Find the choice nodenames for the question in survey mapped (flat) then use those as answers
              element.data.choices.data.forEach(choice => {
                const answer = this.answers[choice.nodeName]
                if (answer.value !== null) {
                  form.set(choice.nodeName, answer.value.toString())
                }
              })
            /**
            * No need to add answers that are empty (or don't exist).
            * The latter can only happen if the survey was edited post-fact.
            */
            } else if (element.subType === 'file') {
              const fileInput = this.$refs[nodeName][0]
              const answer = this.answers[nodeName]
              if (answer.value !== null) {
                const file = fileInput.getFile()

                if (file && answer.value) {
                  form.set(nodeName, file, answer.value)
                  fileInput.clearCache()
                } else {
                  form.set(nodeName, answer.value)
                }
              }
            } else {
              // Answers only support string values for any value
              const answer = this.answers[nodeName]
              if (answer.value !== null) {
                form.set(nodeName, answer.value.toString())
              }
            }
          })

          if (!this.preview && this.anyAnswersChanged()) {
            this.postingAnswers = true
            const answerResult = await this.Api.response.createAnswers(this.responseKey, this.currentSurveyPage.pageNumber, form)
            // Successful posts return 204, no need to see the result posted
            if (answerResult.status === 204) {
              this.postingAnswers = false
              this.displayPrivacyPolicy = false
            } else {
              throw new Error(`Could not post answers. ${answerResult.status} - ${answerResult.statusText}`)
            }
          } else {
            this.postingAnswers = false
            this.displayPrivacyPolicy = false
          }

          // Ensure the "next" page actually exists in the survey structure
          if (this.nextPageExists) {
            this.currentFilteredPageNumber += adjustment
          } else {
            // Next page doesn't exist, wrap up the survey
            if (this.surveyCollectsPersonalInformation) {
              // Survey collects personal info, display privacy policy
              this.displayPrivacyPolicy = true
            } else {
              // Next page doesn't exist and survey doesn't collect personal info, so we're done here
              this.completeSurvey()
            }
          }
        } else if (direction === 'prev') {
          // Moving backwards should take you to the previous page and not display privacy policy if that's displayed
          this.currentFilteredPageNumber += adjustment
          this.displayPrivacyPolicy = false
        }
        // In preview mode, no info is stored about the survey, so we can't save page progress either.
        if (!this.preview) {
          this.storeSurveyPageIndex({
            surveyKey: this.surveyKey,
            pageIndex: this.currentFilteredPageNumber
          })
        }
      } catch (e) {
        this.storeError({
          type: 'fatal',
          message: e.message
        })
      } finally {
        this.postingAnswers = false
        this.$progress.finish()
      }
    }
  },
  async mounted () {
    try {
      this.loading = true
      this.$progress.start()
      // Ensure there is a survey key provided
      if (!this.surveyKey) {
        throw new Error('No Survey Key provided')
      }
      // Fetch the survey
      const survey = await this.Api.survey.get(this.surveyKey)
      // Ensure the survey exists
      if (survey.status !== 200) {
        throw new Error('Survey does not exist')
      }
      // Ensure we have the survey type property
      if (survey.data.isAnonymous === undefined) {
        throw new Error('Survey type is missing')
      }
      // Type check is important here, since it's a nullable boolean. Any other value would mean the user has chosen them
      if (survey.data.isAnonymous === false && !this.participantKey && !this.preview) {
        throw new Error('This survey is personal and requires a valid participant key')
      }
      // If a participant key (or whatever other) parameter is provided for anonymous surveys, gracefully remove it from the URL
      if (survey.data.isAnonymous === true && this.participantKey) {
        this.$router.replace({
          name: 'Index',
          params: {
            surveyKey: this.surveyKey
          }
        })
      }
      // Validate survey data
      const ajv = new Ajv2020({ allErrors: true })
      // Add custom keyword for checking case-insensitive enums
      ajv.addKeyword('customEnum', {
        type: 'string',
        schemaType: 'array',
        compile (schema) {
          const loweredEnums = schema.map(e => typeof e === 'string' ? e.toLowerCase() : e)
          return (data) => loweredEnums.includes(typeof data === 'string' ? data.toLowerCase() : data)
        }
      })
      const validate = ajv.compile(SurveySchema)
      const valid = validate(survey.data)
      if (!valid) {
        /**
        * Create text out of validation error objects. We format the message
        * with some basic HTML tags to make these (usually) long errors
        * more readable.
        */
        let errors = 'Survey structure contains errors: <br />'
        if (validate.errors.length) {
          validate.errors.forEach(e => {
            errors += `${e.instancePath}: ${e.message}`
            errors += '<br />'
          })
        }
        throw new Error(errors)
      }
      // Allright, we have a properly setup survey, setup some requisites
      this.survey = survey.data
      // Retrieve translations for the survey
      await this.getTranslations(this.survey?.language)

      document.title = ((this.survey.title || '').trim().length !== 0) ? this.survey.title + ' - Feedback © BDO' : this.survey.name + ' - Feedback © BDO'

      // If we have a response cached for this personal survey but the participant key has changed, clear the cache.
      // Edge case: Only happens if multiple participants respond using the same exact device and browser (e.g. a shared computer).
      if (survey.data.isAnonymous === false &&
        this.surveys[this.surveyKey] &&
        this.surveys[this.surveyKey].responseKey &&
        this.surveys[this.surveyKey].participantKey !== this.participantKey) {
        this.clearSurveyMetaData(this.surveyKey)
      }

      if (!this.preview) {
        // Check whether there exists data about this survey and response in local storage
        if (this.surveys[this.surveyKey] === undefined || !this.surveys[this.surveyKey].responseKey) {
          console.dir('No survey entry and/or corresponding response key was found, creating new response')
          // No response key for this survey was found, send a POST request to create one and update the URL. Participant Key will be null for anonymous surveys
          const createResponse = await this.Api.response.create(this.surveyKey, this.participantKey)
          if (createResponse.status === 200) {
            // Store the response key to local storage
            this.storeSurveyMetaData({
              surveyKey: this.surveyKey,
              responseKey: createResponse.data.deliveryKey,
              participantKey: this.participantKey,
              pageIndex: this.currentFilteredPageNumber
            })
            this.response = createResponse.data
          } else {
            throw new Error(`Response could not be created, error: ${createResponse.status} - ${createResponse.statusText}`)
          }
        // There is an entry for this survey in local storage and there is a response key. Fetch the response details.
        } else {
          // Allow for continuing the survey where users left
          this.currentFilteredPageNumber = this.surveys[this.surveyKey].pageIndex
          const response = await this.Api.response.get(this.responseKey)
          if (response.status === 200) {
            // Ensure the response's surveyKey matches the survey key provided (data consistency)
            if (response.data.surveyKey !== this.surveyKey) {
              throw new Error('Response survey key does not belong to the provided survey. Please report this to feedback@bdo.no')
            }
            this.response = response.data
          } else {
            throw new Error('This response does not exist (' + this.surveys[this.surveyKey].responseKey + ')')
          }
        }
      } else {
        this.response = {
          answers: [],
          deliveryId: null,
          deliveryKey: null,
          surveyKey: this.surveyKey
        }
      }
      /**
      * survey.data has all the survey structure (elements) and
      * response.answers existing answers (if any). This creates the answer
      * model and merges any existing answers to where they belong.
      */
      this.createAnswerModel(this.survey.data, this.response.answers)
      this.surveyLoaded = true
    } catch (e) {
      // Something went awry when loading required data for the survey
      this.storeError({
        type: 'fatal',
        message: e.message
      })
      console.error(e)
      this.errorLoadingSurvey = true
      // Read docs on no-throw-literal: https://eslint.org/docs/rules/no-throw-literal
      if (e.message.toLowerCase() === 'survey does not exist') {
        this.$router.push({
          name: 'NotFound',
          params: {
            surveyKey: this.surveyKey
          }
        })
      }
    } finally {
      this.loading = false
      this.$progress.finish()
    }
  }
})
</script>
<style lang="less">
  .survey-theme-color {
    background-color: var(--survey-theme-color) !important;
    color: var(--survey-text-color) !important;
  }
  .survey-background {
    // background: url('/img/default-bg.webp') top center fixed #fefefe;
    background-size: cover;
    background-position: center center;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: -1;
  }

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
