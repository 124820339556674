<template>
  <div
    class="d-flex justify-content-between align-items-center flex-sm-col"
  >
    <div class="col-md-8">
      <p v-show="!logoOnly" class="title-sm title h2 font-weight-light">{{ survey.title }}</p>
      <div
        v-show="!logoOnly"
        v-if="survey.endDate"
        class="text-smaller fst-italic"
      >
        {{ translations['survey-ends'] || 'Survey ends'}}: {{ endDateFormatted }}
      </div>
    </div>
    <div
      v-if="survey.logo"
      class="text-end logo-sm">
      <img
        :src="survey.logo"
        loading="lazy"
        class="mb-1"
        alt="Survey Logo"
      />
    </div>
  </div>
  <div class ="d-flex justify-content">
    <div class="col-10 subtitle">
      <h3 v-show="!logoOnly">{{ survey.subTitle }}</h3>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import DateFormat from 'date-and-time'
export default defineComponent({
  name: 'Header',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    logoOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      survey: {},
      dateFormatter: Date
    }
  },
  computed: {
    ...mapGetters([
      'translations'
    ]),
    endDateFormatted () {
      if (!this.survey.endDate) {
        return this.$t('neverEnds')
      } else {
        const endDate = new Date(this.survey.endDate)
        return DateFormat.format(endDate, 'DD.MM.YYYY HH:mm')
      }
    }
  },
  mounted () {
    this.survey = this.data
  }
})
</script>
