import axios from 'axios';
const responseInstance = axios.create({
    baseURL: process.env.VUE_APP_RESPONSES_URL
});
const survey = {
    /**
    * Retrieves a survey identified by survey key.
    * @param {String} surveyKey - uuid for a survey
    * @return {Promise} Axios promise
    */
    get: (surveyKey) => {
        return responseInstance.get(`surveys/${surveyKey}`);
    }
};
const response = {
    /**
    * Retrieves a response identified by response key.
    * @param {String} responseKey - uuid for a response
    */
    get: (responseKey) => {
        return responseInstance.get(`responses/${responseKey}`);
    },
    /**
    * Creates a response
    * @param {String} surveyKey - uuid for a survey
    * @param {String} participantKey - uuid for a participant (optional)
    */
    create: (surveyKey, participantKey) => {
        return responseInstance.post(`responses/?surveyKey=${surveyKey}&participantKey=${participantKey}`);
    },
    /**
    * Creates a response
    * @param {String} responseKey - uuid for the response
    * @param {Array} answers - Collection of answer objects in an array
    */
    createAnswers: (responseKey, pageNumber, form) => {
        return responseInstance.post(`responses/${responseKey}/${pageNumber}/answers`, form);
    },
    /**
    * Completes a response (survey)
    * @param {String} responseKey - uuid for the response
    */
    complete: (responseKey) => {
        return responseInstance.post(`responses/${responseKey}/complete`);
    },
    /**
    * Sends an email (receipt), identified by survey key and response key.
    * @param {String} surveyKey - uuid for a survey
    * @param {String} responseKey - uuid for a response
    * @return {Promise} Axios promise
    */
    sendReceipt: (surveyKey, responseKey, emailAddress) => {
        // https://azapp-feedback-responses-<environment>.azurewebsites.net/sendReceipt?responseKey=<deliveryKey>&surveyKey=<surveyKey>&email=<email>
        const data = {
            responseKey: responseKey,
            surveyKey: surveyKey,
            email: emailAddress
        };
        return responseInstance.post('responses/sendReceipt', data);
    }
};
const Api = {
    survey,
    response
};
export default Api;
