<template>
  <!-- Light mode displays only the loading animation -->
  <img
    v-if="light === true"
    src="/img/loading.gif"
    class="loading mr-2"
    alt="loading"
  />
  <div
    v-else
    :variant="variant"
    class="alert loading my-2 text-center"
    :class="`alert-${variant}`"
    role="alert"
  >
    <img
      src="/img/loading.gif"
      class="me-2"
      width="25"
      height="25"
      alt="Feedback is loading, please wait..."
      sr-only="Feedback is loading, please wait..."
    />
    {{ message || `${translations['survey-please-wait']}&hellip;` || `Loading&hellip;` }}
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
export default defineComponent({
  name: 'Loading',
  props: {
    message: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'default'
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'translations'
    ])
  }
})
</script>
<style scoped>
  img {
    box-shadow: 0 0 4px #aeaeae;
    border-radius: 50%;
  }
</style>
