<!-- Component for all decorational elements (header, spacer, textblock), i.e non-questions -->
<template>
  <!-- Decorational element Header -->
  <h4
    v-if="elementType === 'header'"
    class="decoration header header-color"
    :style="headerColorStyle"
  >
    {{ elementText }}
  </h4>
  <!-- Decorational element Textblock / Paragraph -->
  <div
    v-else-if="elementType === 'textblock'"
    v-html="DOMPurify.sanitize(Marked(element.data.text))"
    class="decoration textblock"
  >
  </div>
  <!-- Decorational element Spacer -->
  <div
    v-else-if="elementType === 'spacer'"
    :class="spacerClass"
  >
  </div>

</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DecorationElement',
  inject: [
    'DOMPurify',
    'Marked'
  ],
  props: {
    element: {
      type: Object,
      required: true
    },
    themeHeaderColor: {
      type: String,
      default: '#02a5e2'
    }
  },
  computed: {
    elementType () {
      return this.element.subType
    },
    elementText () {
      /**
      * FBE employs survey schema validation. By this point (in code) attributes
      * are expected to be (t)here, but some may have null value.
      */
      if ('data' in this.element && 'text' in this.element.data) {
        return this.element.data.text
      } else {
        return null
      }
    },
    spacerClass () {
      let cssClass = ''
      if ('custom' in this.element && 'size' in this.element.custom) {
        /* Converts attribute values to actual bootstrap margin classes */
        switch (this.element.custom.size) {
          case 'small':
            cssClass = 'my-3'
            break
          case 'medium':
            cssClass = 'my-4'
            break
          case 'large':
            cssClass = 'my-5'
            break
        }
      }
      return cssClass
    },
    headerColorStyle () {
      return {
        '--theme-color': this.themeHeaderColor
      }
    }
  }
})
</script>
<style type="less" scoped>
  h4 {
    color: var(--theme-color);
    font-weight: lighter;
  }
</style>
