<template>
  <div class="privacy-policy">
    <h6
      v-if="name"
      class="fst-italic text-end text-smaller my-3"
    >
      {{ translations['privacy-policy'] || 'Privacy Policy' }} - {{ name }}
    </h6>
    <hr>
    <!-- Privacy policy content -->
    <div
      v-if="content"
      v-html="DOMPurify.sanitize(Marked(contentParsed))"
      class="text-size-normal"
    >
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
export default defineComponent({
  name: 'PrivacyPolicy',
  inject: [
    'Marked',
    'DOMPurify'
  ],
  props: {
    name: {
      type: String,
      default: '',
      required: true
    },
    slug: {
      type: String,
      default: '',
      required: true
    },
    content: {
      type: String,
      default: '',
      required: true
    },
    questionsCollectingPersonalInformation: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  computed: {
    contentParsed () {
      return this.content.replace('{personal-input-list}', this.questionsParsed)
    },
    questionsParsed () {
      let output = ''
      if (this.questionsCollectingPersonalInformation.length) {
        this.questionsCollectingPersonalInformation.forEach(e => {
          // Formatting in Markdown is strict. Enforce a newline to avoid pre-formatted output
          output += '- **' + e.data.text + '** \n'
        })
      }
      return output
    },
    ...mapGetters([
      'translations'
    ])
  }
})
</script>
