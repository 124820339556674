<!-- Component for multiple choice elements -->
<template lang="html">
  <div v-if="element">
    <!-- Multiple Choice - Dropdown type -->
    <select
      v-if="element.subType === 'select'"
      v-model="answers[element.nodeName].value"
      :name="element.nodeName"
      class="form-control"
    >
      <option
        v-for="(option, optionIndex) in element.data.choices.data"
        :key="`page-${currentPageNumber}-element-${elementIndex}-option-${optionIndex}`"
        :value="option.text"
        @change="updateDependencyTree(option, element, currentPageNumber, elementIndex)"
      >
        {{ option.text }}
      </option>
    </select>
    <div v-else>
      <div
        v-for="(choice, choiceIndex) in element.data.choices.data"
        :key="`survey-page-${currentPageNumber}-element-${elementIndex}-choice-${choiceIndex}`"
        class="form-control no-border"
      >
        <div>
          <!-- Radio / Multiple choice -->
          <label
            v-if="element.subType === 'radio' || element.subType === 'checkbox'"
            class="d-flex align-items-center"
          >
            <!-- Multiple Choice - Checkbox type -->
            <!-- Multiple choice checkboxes values are concat-ed with ; and cannot be mapped directly to v-model -->
            <input
              v-if="element.subType === 'checkbox'"
              @change="handleValue(choice, element, currentPageNumber, elementIndex, $event)"
              :name="element.nodeName"
              :type="element.subType"
              :value="choice.text"
              :checked="checkboxValues.find(e => e === choice.text)"
            />
            <!-- Multiple Choice - Radio button type -->
            <input
              v-else-if="element.subType === 'radio'"
              v-model="answers[element.nodeName].value"
              :name="element.nodeName"
              :type="element.subType"
              :value="choice.text"
              @change="updateDependencyTree(choice, element, currentPageNumber, elementIndex)"
            />

            <span class="option-text ms-2">
              {{ choice.text }}
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
export default defineComponent({
  name: 'MultipleChoiceElement',
  data () {
    return {
      checkboxValues: []
    }
  },
  props: {
    currentPageNumber: Number,
    elementIndex: Number,
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'answers'
    ])
  },
  mounted () {
    const value = this.answers[this.element.nodeName].value || ''
    this.checkboxValues = value.split(';')
  },
  methods: {
    ...mapActions([
      'storeDependencyTree'
    ]),
    updateDependencyTree (option, element, pageIndex, elementIndex) {
      this.storeDependencyTree({
        id: option.id,
        value: option.text,
        type: element.type,
        subType: element.subType,
        nodeName: option.nodeName,
        pageIndex: pageIndex,
        elementIndex: elementIndex
      })
    },
    handleValue (choice, element, pageIndex, elementIndex, event) {
      // Array is only used to simplify value comparative searches
      const value = event.target.value
      const valueIndex = this.checkboxValues.findIndex(e => e === value)
      if (event.target.checked) {
        // Check if value exists in array, to avoid duplicate values
        if (valueIndex < 0) {
          this.checkboxValues.push(value)
        }
      } else {
        // Value was unchecked, remove from checkboxvalues
        this.checkboxValues.splice(valueIndex, 1)
      }
      this.updateDependencyTree(choice, element, pageIndex, elementIndex)
      // Convert any changes to string and save to Vuex
      // Values must be concatinated to a string separated with ;. Hello 2006.
      this.answers[this.element.nodeName].value = this.checkboxValues.join(';')
    }
  }
})
</script>
