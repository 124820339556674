<template lang="html">
  <div class="my-3">
    <p v-html="introductionText"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Introduction',
  inject: [
    'Marked',
    'DOMPurify'
  ],
  props: {
    data: {
      type: [String, null],
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'translations'
    ]),
    introductionText () {
      let introductionText = ''
      if (this.data) {
        introductionText = this.data
      }
      return this.DOMPurify.sanitize(this.Marked(introductionText))
    }
  }
})
</script>
