<!-- Insight is a component for debugging only, and is only shown in non-production environments -->
<template lang="html">
  <!-- Ensure there is debug data to show -->
  <div
    v-if="data && Object.keys(data).length"
    class="insight-container position-fixed bottom-0 start-0"
  >
    <div class="m-2">
      <button
        class="btn btn-sm btn-primary me-3"
        @click="showDebug = !showDebug"
      >
        <font-awesome-icon
          :icon="['fas', showDebug === true ? 'eye-slash' : 'eye']"
          class="mr-2"
          fixed-width
        >
        </font-awesome-icon>
        Insight
      </button>
      <button
        @click="clearLocalData()"
        class="btn btn-sm btn-success"
      >
        Clear local data
      </button>
      <small class="text-smaller fst-italic ms-2">
        v{{ version }}
      </small>
    </div>
    <div
      v-if="Object.keys(data).length && showDebug === true"
      class="insight-window position-absolute top-0 start-0 vh-100 position-fixed overflow-y p-3"
    >
      <button
        @click="showDebug = false"
        class="btn btn-default p-0 position-relative start-100 top-0"
        title="Close Insight"
      >
        <font-awesome-icon
          :icon="['fas', 'xmark']"
          fixed-width
        >
        </font-awesome-icon>
      </button>
      <h4>
        <font-awesome-icon
          :icon="['fas', 'eye']"
          class="mr-2"
          fixed-width
          beat
        >
        </font-awesome-icon>
        Survey Insight
      </h4>
      <div class="d-flex align-items-center">
        <img
          src="/img/version.jpg"
          width="30"
          class="rounded me-2"
          alt="logo"
        />
        <div class="fst-italic text-smaller">
          {{ version }} - {{ codename }}
        </div>
      </div>
      <h5 class="mt-3">Vitals</h5>
      <hr class="mt-0" />
      <ul>
        <li
          v-for="(vital, vitalIndex) in debugFormatted.vitals"
          :key="`vital-${vitalIndex}`"
        >
          <h6 class="text-smaller">
            {{ `${vital.heading}: ${vital.data}` }}
            <font-awesome-icon
              v-if="vital.description"
              :icon="['fas', 'question-circle']"
              :title="vital.description"
              class="text-blue pointer"
            />
          </h6>
        </li>
      </ul>
      <h5 class="mt-4">Structures and models</h5>
      <hr class="mt-0" />
      <div
        class="accordion accordion-flush"
        id="insight-categories"
      >
        <div
          v-for="(structure, structureIndex) in debugFormatted.structures"
          :key="`insight-element-${structureIndex}`"
          class="accordion-item"
        >
          <h4
            class="accordion-header"
            :id="`heading-${structureIndex}`"
          >
            <button
              class="accordion-button collapsed text-smaller p-2"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#insight-category-${structureIndex}`"
              :aria-controls="`insight-category-${structureIndex}`"
              :aria-expanded="false"
            >
              <font-awesome-icon
                :icon="['fas', 'magnifying-glass']"
                class="me-2"
                fixed-width
              >
              </font-awesome-icon>
              {{ structure.heading }}
            </button>
          </h4>
          <div
            :id="`insight-category-${structureIndex}`"
            class="accordion-collapse collapse"
            :aria-labelledby="`heading-${structureIndex}`"
          >
            <div class="accordion-body p-1 text-smaller">
              <p class="fst-italic mt-2">
                {{ structure.description }}
              </p>
              <!-- NOTE: There's an issue with whitespacing on pre-formatted blocks, so the code formatting here
              is uneven to make the structure display correctly. -->
              <pre
                v-if="structure.data && Object.keys(structure.data).length"
                class="bg-light p-2"
              > {{ structure.data }}
              </pre>
              <p
                v-else
                class="text-red"
              >
                 <em>Nothing here.</em>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="fst-italic text-smaller my-3">
        Insight is only available in development environments.
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import packageFile from '../../package.json'
export default defineComponent({
  name: 'Debug',
  data () {
    return {
      showDebug: false,
      environment: process.env.NODE_ENV,
      version: packageFile.version,
      codename: packageFile.codename
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    debugFormatted () {
      const output = {
        structures: [],
        vitals: []
      }
      if (this.data && 'structures' in this.data && 'vitals' in this.data) {
        output.structures = [
          {
            heading: `Required questions on page (${Object.keys(this.data.structures.requiredQuestionsOnPage).length})`,
            description: 'Shows which questions are required on this page',
            data: this.data.structures.requiredQuestionsOnPage
          },
          {
            heading: `Questions collecting personal information (${Object.keys(this.data.structures.questionsThatCollectPersonalInformation).length})`,
            description: 'Shows which questions collect personal information',
            data: this.data.structures.questionsThatCollectPersonalInformation
          },
          {
            heading: `Current Survey Page (${this.data.structures.currentSurveyPage.elements !== null ? Object.keys(this.data.structures.currentSurveyPage.elements).length : 0} elements)`,
            description: 'What elements the current page consists of',
            data: this.data.structures.currentSurveyPage.elements
          },
          {
            heading: `Answer Model (${Object.keys(this.data.structures.answerModel).length} answers)`,
            description: 'Answers (existing ones from an earlier response, and those selected now)',
            data: this.data.structures.answerModel
          },
          {
            heading: `Dependency Tree (${Object.keys(this.data.structures.dependencyTree).length} values)`,
            description: 'A list of elements that correspond to IDs and nodenames of options selected',
            data: this.data.structures.dependencyTree
          },
          {
            heading: `Entire Survey (${Object.keys(this.data.structures.surveyMapped).length} elements)`,
            description: 'The entire survey flattened and mapped by nodename',
            data: this.data.structures.surveyMapped
          },
          {
            heading: `Existing Response (${Object.keys(this.data.structures.existingAnswers).length} answers)`,
            description: 'Answers from an existing response. This is usually when a user has started (or finished) the survey before.',
            data: this.data.structures.existingAnswers
          }
        ]
        output.vitals = [
          {
            heading: 'Current page number',
            description: null,
            data: this.data.vitals.currentPageNumber
          },
          {
            heading: 'Number of survey pages',
            description: 'The total survey length can vary if there are elements that depend on others',
            data: this.data.vitals.calculatedSurveyLength
          },
          {
            heading: 'Survey Type',
            description: null,
            data: this.data.vitals.isAnonymous === true ? 'Anonymous' : 'Personal'
          },
          {
            heading: 'Collects personal information',
            description: 'Does the survey collect personal information?',
            data: this.data.vitals.surveyCollectsPersonalInformation
          }
        ]
      }
      return output
    }
  },
  methods: {
    clearLocalData () {
      window.localStorage.removeItem('feedback-survey')
    }
  }
})
</script>
<style lang="less" scoped>
  .accordion-collapse {
    max-width: 450px;
  }
  .insight-container {
    z-index: 15;
  }
  .insight-window {
    width: 450px;
    max-width: 60%;
    background: rgba(255, 255, 255, 0.95);
  }
</style>
