// Define custom renders for Marked. Docs: https://marked.js.org/using_pro#renderer
export const renderer = {
    image(href, title, text) {
        return `
    <img
      src="${href}"
      name="image-${text.toLowerCase()}"
      alt="survey image ${title || text.toLowerCase()}"
      class="img-fluid"
      loading="lazy"
      title="${title || text}"
    />
    `;
    },
    link(href, title, text) {
        const titleAttr = title ? `title="${title}"` : '';
        return `
    <a href="${href}" ${titleAttr} target="_blank"> ${text} </a>`;
    }
};
