import { createRouter, createWebHistory } from 'vue-router';
import Index from '../views/Index.vue';
const routes = [
    // Do not chunk the main landing, should be loaded automatically for optimized TTFB.
    {
        path: '/:surveyKey/:participantKey?',
        name: 'Index',
        component: Index
    },
    {
        path: '/',
        name: 'About',
        component: () => import(/* webpackChunkName: "feedback-engine-about" */ '../views/About.vue')
    },
    {
        path: '/terms-of-use',
        name: 'TermsOfUse',
        component: () => import(/* webpackChunkName: "feedback-engine-terms-of-use" */ '../views/TermsOfUse.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "feedback-engine-not-found" */ '../views/NotFound.vue')
    }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
