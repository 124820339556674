import { createApp } from 'vue';
import App from './App.vue';
import Api from './core/Api';
import router from './router';
import store from './store';
import Config from './../config/Config';
import { marked } from 'marked';
import { renderer } from './core/MarkedRenderer';
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line
import 'bootstrap';
import DOMPurify from '@/core/DOMPurify';
// eslint-disable-next-line
import Vue3Progress from "vue3-progress/src/index.js";
import FontAwesomeIcon from '@/core/FontAwesomeIcon';
import Tooltip from '@/components/Common/Tooltip.vue';
import '@/assets/styles/app.less';
// Set markdown style defaults
marked.use({ renderer });
const progressOptions = {
    position: 'fixed',
    height: '3px',
    color: '#02a5e2'
};
const app = createApp(App);
app
    .use(store)
    .use(router)
    .use(Vue3Progress, progressOptions)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('Tooltip', Tooltip)
    .provide('Api', Api)
    .provide('Marked', marked)
    .provide('Config', Config)
    .provide('DOMPurify', DOMPurify)
    .mount('#feedback-survey-engine');
