<template>
  <footer
    class="p-0 mt-4"
  >
    <hr class="solid mb-3">
    <div class="logos mx-auto d-flex justify-content-center">
      <div :title="`v${version} - Feedback is made in Trondheim, Norway. High Five!`">
          <img
            src="/img/feedback-high-five.svg"
            width="50"
            alt="Feedback High Five"
          />
      </div>
    </div>
    <div class="logos mx-auto d-flex align-items-center justify-content-between rounded">
      <a
        href="https://feedback.bdo.no"
        target="_blank"
        rel="noopener"
      >
        <img
          src="/img/logo-black.svg"
          width="100"
          alt="logo"
          title="Read more about Feedback."
        />
      </a>
      <div class="text-center text-smaller mt-2 me-4">
        <div>
          <span :title="`Version ${version}`">
            &copy; {{ currentYear }}
          </span>
          <span v-if="translations && 'user-terms-title' in translations">
            -
            <a
              href="/terms-of-use"
              target="_blank"
              rel="noopener"
            >
              {{ translations['user-terms-title'] || 'User terms' }}
            </a>
          </span>
        </div>
      </div>
      <a
        href="https://bdo.no"
        target="_blank"
        rel="noopener"
      >
        <img
          src="/img/bdo-logo.svg"
          class="me-3"
          width="50"
          alt="BDO Logo"
          title="Visit BDO.no"
        />
      </a>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import packageFile from '../../../package.json'
export default defineComponent({
  name: 'Footer',
  data () {
    return {
      environment: process.env.NODE_ENV,
      version: packageFile.version,
      codename: packageFile.codename
    }
  },
  computed: {
    ...mapGetters([
      'translations'
    ]),
    currentYear () {
      const date = new Date()
      return date.getFullYear()
    }
  }
})
</script>
<style
  lang="less"
  scoped
>
  footer .logos {
    background-size: 50px;
    max-width: 1000px;
  }
</style>
