<template lang="html">
  <div>
    <content-loader
      v-if="show"
      viewBox="0 0 400 200"
      :speed="1"
      primary-color="#f2f2f2"
      secondary-color="#02a5e2"
      class="content-placeholder"
    >
      <rect x="5" y="30" rx="1" ry="1" width="250" height="12" />
      <rect x="319" y="60" rx="1" ry="1" width="65" height="8" />
      <circle cx="352" cy="30" r="20" />
      <rect x="5" y="90" rx="1" ry="1" width="180" height="20" />
      <rect x="205" y="90" rx="1" ry="1" width="180" height="20" />
      <rect x="5" y="120" rx="0" ry="1" width="180" height="20" />
      <rect x="205" y="120" rx="1" ry="1" width="180" height="20" />
      <rect x="5" y="150" rx="0" ry="1" width="180" height="20" />
      <rect x="205" y="150" rx="1" ry="1" width="180" height="20" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Placeholder',
  data () {
    return {
      show: false
    }
  },
  components: {
    ContentLoader
  },
  mounted () {
    this.show = true
  }
})
</script>
