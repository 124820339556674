<!-- Component for textarea elements -->
<template>
  <div class="w-100">
    <textarea
      v-model="answers[element.nodeName].value"
      :ref="element.nodeName"
      class="textarea-element form-control shadow-light"
      :class="v$.$dirty && v$.elementValue.$invalid ? 'border-red' : 'border-green'"
      @keyup="updateHeight()"
      :placeholder="translations['textarea-placeholder']"
      :name="element.nodeName"
    >
    </textarea>
    <div
      v-if="v$.$dirty && v$.elementValue.$invalid"
      class="fst-italic text-red text-smaller"
    >
      {{ v$.$errors[0].$message }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { required } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'

export default defineComponent({
  name: 'TextareaElement',
  props: {
    currentPageNumber: Number,
    elementIndex: Number,
    element: {
      type: Object,
      required: true
    }
  },
  setup: () => ({
    v$: useVuelidate()
  }),
  validations () {
    let rules = {}
    if (this.element.required) {
      rules = {
        required
      }
    }
    return {
      elementValue: rules
    }
  },
  watch: {
    elementValue () {
      /**
      * Due to our 'unique' setup of computed values, Vuelidate doesn't directly mutate
      * the model, it's inferred via an intermediary, and as such a manual touch
      * upon value change is required for the $dirty state to set correctly.
      */
      this.v$.elementValue.$touch()
    }
  },
  methods: {
    updateHeight () {
      const el = this.$refs[this.element.nodeName]
      el.style.height = 'auto'
      el.style.height = el.scrollHeight + 5 + 'px'
    }
  },
  computed: {
    ...mapGetters([
      'answers',
      'translations'
    ]),
    elementValue () {
      if (this.element.nodeName in this.answers) {
        return this.answers[this.element.nodeName].value
      } else {
        return ''
      }
    }
  }
})
</script>
<style type="less" scoped>
  .textarea-element {
    min-height: 120px;
    max-height: 300px;
  }
</style>
