import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
export default createStore({
    plugins: [createPersistedState({
            key: 'feedback-survey',
            paths: [
                'answers',
                'surveys',
                'translations'
            ]
        })],
    state: {
        // Represents all answers to all questions in a survey
        answerModel: {},
        // Represents what values have been selected from elements that control dependency (multiple choice for now)
        dependencyTree: {},
        // Represents all metadata about any surveys visited
        surveys: {},
        translations: {},
        answers: {},
        errors: {}
    },
    actions: {
        clearErrors({ commit }) {
            commit('deleteErrors');
        },
        storeAnswerModel({ commit }, data) {
            commit('saveAnswerModel', data);
        },
        storeDependencyTree({ commit }, data) {
            commit('saveDependencyTree', data);
        },
        storeError({ commit }, data) {
            commit('saveError', data);
        },
        // Saves only meta data about any survey visited, like responseKey, surveyKey and participantKey (if the survey is personal)
        storeSurveyMetaData({ commit }, data) {
            commit('saveSurveyMetaData', data);
        },
        // Clear meta data for a specific survey
        clearSurveyMetaData({ commit }, surveyKey) {
            commit('deleteSurveyMetaData', surveyKey);
        },
        // Saves the page progression for the survey the user is visiting
        storeSurveyPageIndex({ commit }, data) {
            commit('saveSurveyPageIndex', data);
        },
        storeTranslations({ commit }, data) {
            commit('saveTranslations', data);
        }
    },
    mutations: {
        deleteErrors(state) {
            console.dir('Clearing errors');
            state.errors = {};
        },
        saveError(state, data) {
            state.errors = data;
        },
        saveAnswerModel(state, data) {
            state.answers = data;
        },
        saveSurveyMetaData(state, data) {
            const surveyData = {
                surveyKey: data.surveyKey,
                responseKey: data.responseKey,
                participantKey: data.participantKey,
                pageIndex: data.pageIndex
            };
            state.surveys[surveyData.surveyKey] = surveyData;
        },
        deleteSurveyMetaData(state, surveyKey) {
            delete state.surveys[surveyKey];
        },
        saveSurveyPageIndex(state, data) {
            state.surveys[data.surveyKey].pageIndex = data.pageIndex;
        },
        saveDependencyTree(state, data) {
            const answers = Object.entries(state.dependencyTree);
            let res = [];
            const entry = data;
            /*
            * Checkboxes naturally offer an answer to consist of multiple values, so check if the selected nodeName is present
            * Since nodeNames are (supposed) to be unique, we can skip elementIndex and pageIndex checks.
            */
            if (data.subType === 'checkbox') {
                res = answers.find((e) => e[1].nodeName === data.nodeName);
            }
            else {
                /*
                * Otherwise radio buttons. These have different IDs too, but may refer to the same index (element).
                * Radio buttons represent 1 unique value. As such, we need to find out whether the current ID points
                * to the same element on the same page.
                * NOTE: Note that elements on different pages may have the same element index,
                * so this must be taken into consideration.
                */
                res = answers.find((e) => e[1].pageIndex === data.pageIndex && e[1].elementIndex === data.elementIndex);
            }
            if (res) {
                // RES should only contain 1 result, but in case it doesn't:
                const nodeName = res[0];
                delete state.dependencyTree[nodeName];
                if (data.subType !== 'checkbox') {
                    state.dependencyTree[data.nodeName] = entry;
                }
            }
            else {
                state.dependencyTree[data.nodeName] = entry;
            }
        },
        saveTranslations(state, data) {
            state.translations = data;
        }
    },
    getters: {
        dependencyTree: state => {
            return state.dependencyTree;
        },
        answers: state => {
            return state.answers;
        },
        surveys: state => {
            return state.surveys;
        },
        errors: state => {
            return state.errors;
        },
        translations: state => {
            return state.translations;
        }
    },
    modules: {}
});
